// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import * as bootstrap from 'bootstrap';

import IMask from 'imask';
import validator from 'validator';
import 'bs5-lightbox';
import Masonry from 'masonry-layout';

// Your app code
document.addEventListener("DOMContentLoaded", function () {
  // search();
  // filter();
  // formHelp();
  // mask();

  var msnry = new Masonry('.mnsr-grid', {
    itemSelector: '.mnsr-grid-item',
    percentPosition: true
  });
});

function search() {
  let searchElement = document.querySelector("[js-search]");

  if (searchElement) {
    console.log("js-search");
    let number = searchElement.getAttribute("data-number");
    partSearch(number);
  }

  document.addEventListener("click", function (e) {
    if (e.target.hasAttribute("js-part-show")) {
      e.preventDefault();
      let value = e.target.dataset.value;
      console.log(value);

      document.querySelector("tr[data-value='" + value + "']").classList.remove("d-none");
      e.target.closest("tr").remove();
    }
  });

  function partSearch(number) {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", "/api/ajax/supplier/list", true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        let response = JSON.parse(xhr.responseText);
        if (response.length) {
          response.forEach(function (value) {
            let xhrPartSearch = new XMLHttpRequest();
            xhrPartSearch.open("POST", "/api/ajax/part/search", true);
            xhrPartSearch.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhrPartSearch.onreadystatechange = function () {
              if (xhrPartSearch.readyState === 4 && xhrPartSearch.status === 200) {
                document.querySelector("[js-search]").innerHTML = xhrPartSearch.responseText;
              }
            };
            xhrPartSearch.send("number=" + number + "&supplier=" + value.id);
          });
        }
      }
    };
    xhr.send("number=" + number);
  }
}

function filter() {
  document.addEventListener("change", function (e) {
    if (e.target.closest("[js-filter] select")) {
      document.querySelector("[js-filter]").submit();
    }
  });
}

function formHelp() {

  const form = document.querySelector("[js-form-help]")

  form.addEventListener('submit', event => {
    if (!form.checkValidity()) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      const formData = new FormData(form);

      let xhr = new XMLHttpRequest();
      xhr.open("POST", "/api/ajax/form/help", true);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log(xhr.responseText);
          } else {
            console.error("Error: " + xhr.status);
          }
        }
      };
      xhr.send(formData);

      var formThank = new bootstrap.Modal(document.getElementById('formThank'), {})
      formThank.show()

      for (var i = 0; i < form.elements.length; i++) {
        var element = form.elements[i];

        if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA' || element.tagName === 'SELECT') {
          element.value = '';
        }
      }
    }

    form.classList.add('was-validated')

    event.preventDefault();
  }, false);
}

function mask() {
  let phoneInput = document.querySelector("input[name=phone]");

  if (phoneInput) {
    IMask(phoneInput, {
      mask: "+{7}({9}00)000-00-00",
    });
  }
}

